export const dAppName = 'Super Rare Bears Minter';

export const contractAddress = {
  minter: 'erd1qqqqqqqqqqqqqpgqydwv68j8ysqm59r89e3rl4wc9wy0ja988pgsc0cful',
};
export const gatewayAddress = 'https://gateway.multiversx.com/';
export const ENVIROMENT = 'mainnet';

export const ChainID = '1';
export const network = {
  id: 'mainnet',
  name: 'Mainnet',
  egldLabel: 'EGLD',
  walletAddress: 'https://wallet.multiversx.com',
  apiAddress: 'https://api.multiversx.com',
  gatewayAddress: 'https://gateway.multiversx.com',
  explorerAddress: 'https://explorer.multiversx.com',
  graphQlAddress: 'https://exchange-graph.multiversx.com/graphql',
  apiTimeout: 10000,
};

export const GAS_LIMIT = 60000000;

//general configs
export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';
export const sampleAuthenticatedDomains = [TOOLS_API_URL];

export const walletConnectBridge = 'https://bridge.walletconnect.org';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = 'e12094d60e5b39f852630ee823a5aa37';
