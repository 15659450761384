import React, { useState, useEffect, useCallback } from 'react';
import { ITransactionOnNetwork, Transaction, TransactionHash, TransactionWatcher } from '@multiversx/sdk-core';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks/account/useGetAccount';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks/account/useGetIsLoggedIn';
import { useGetSuccessfulTransactions, useGetSignedTransactions } from '@multiversx/sdk-dapp/hooks/transactions';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers';
import Decimal from 'decimal.js';
import { Button , Form } from 'react-bootstrap';
import { mintNfts } from 'utils/services/calls';
import { useGetCostsInfo, useGetGlobalInfo, useGetPhaseInfo, useGetWatcherResults } from 'utils/services/hooks';
import { chainID, network } from '../../api/net.config';

export const Dashboard = () => {
  const [divVisible, setDivVisible] = useState(true);
  const { address } = useGetAccount();
  // console.log(address);
  const isLoggedIn = useGetIsLoggedIn();
  // console.log('isLoggedIn', isLoggedIn);
  const { globalInfo, isLoadingGlobalInfo } = useGetGlobalInfo();
  //  console.log('globalInfo', isLoadingGlobalInfo, globalInfo);
  const { phaseInfo, isLoadingPhaseInfo } = useGetPhaseInfo(address);
  // console.log('phaseInfo', isLoadingPhaseInfo, phaseInfo);
  const { costsInfo, isLoadingCostsInfo } = useGetCostsInfo(address);
  // console.log('costsInfo', isLoadingCostsInfo, costsInfo);
  const {signedTransactions, signedTransactionsArray, hasSignedTransactions} = useGetSignedTransactions();
  //console.log('hasSignedTransactions', hasSignedTransactions);
  // const { hasSuccessfulTransactions, successfulTransactions, successfulTransactionsArray} = useGetSuccessfulTransactions();
  // console.log('hasSuccessfulTransactions', hasSuccessfulTransactions);

  const innerArray = signedTransactionsArray[0];

  // Access the object within the inner array at index 1
  const firstObject = innerArray ? innerArray[1] : null;

  const [isLoading, setIsLoading] = useState(false);
  const [isReloaded, setIsReloaded] = useState(false);
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const [hasSucceeded2, setHasSucceeded2] = useState(true);// New state to track success

  useEffect(() => {
    if (firstObject) {
      setIsLoading(firstObject.status === 'signed' || firstObject.status === 'sent');

      if (firstObject.status === 'success' && !isReloaded) {
        setIsReloaded(true); // Mark the page as reloaded

      }
    }
  }, [firstObject]);




  let collectionIdentifier = '';
  let totalMinted = null;
  let userMax = null;
  let leftMinted = null;
  if (!isLoadingGlobalInfo && globalInfo && globalInfo.collection_identifier) {
    collectionIdentifier = globalInfo.collection_identifier;
    totalMinted = globalInfo.total_minted;
    leftMinted = globalInfo.left_to_mint;
    userMax = globalInfo.user_max_mints;
  }



  const defaultToken = 'HYPED-eabbc2'; // Replace with the actual default token's cost_token
  const [selectedToken, setSelectedToken] = useState(defaultToken);
  const [tokenDecimals, setTokenDecimals] = useState(null);
  const [numNFTs, setNumNFTs] = useState(1); // Default value is 1
  const imageUrls = ['burgler.png']; // Replace with your image URLs
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [nftsIdsFromNoncesList, setNftsIdsFromNoncesList] = useState(Array);
  const [isLoadingNftsIdsFromNoncesList, setIsLoadingNftsIdsFromNoncesList] = useState(true);

  useEffect(() => {
    if (phaseInfo?.latest_mint_nonces && phaseInfo?.latest_mint_nonces.length > 0 && collectionIdentifier) {
      console.log('Getting NFTs IDs ...');
      const fetchNftsNonces = async () => {
        try {
          const identifiers = phaseInfo?.latest_mint_nonces.map(function (nonce) {
            let nonceToHex = nonce.toString(16);
            if (nonceToHex.length % 2 === 1) {
              nonceToHex = '0' + nonceToHex;
            }
            return collectionIdentifier + '-' + nonceToHex;
          });
          const response = await fetch(network.apiAddress+`/collections/${collectionIdentifier}/nfts?identifiers=${identifiers.join(',')}`);
          const data = await response.json();

          return data.map(function (elem) {
            return elem.name.split('#')[1];
          });
        } catch (error) {
          throw new Error('Unable to fetch nonces info');
        }
      };

      fetchNftsNonces()
        .then((nftsIds) => {
          setNftsIdsFromNoncesList(nftsIds);
          setIsLoadingNftsIdsFromNoncesList(false);
          console.log(nftsIds);
        })
        .catch((error) => {
          console.error('Error fetching nonces ids:', error);
        });;
    }
  }, [phaseInfo?.latest_mint_nonces]);

  useEffect(() => {
    if (selectedToken) {

      const fetchTokenInfo = async (token) => {
        try {
          const tokenToFetch = token === 'EGLD' ? 'WEGLD-bd4d79' : token;
          const response = await fetch(network.apiAddress+`/tokens/${tokenToFetch}`);
          const data = await response.json();
          return data.decimals;
        } catch (error) {
          throw new Error('Unable to fetch token info');
        }
      };
      // Fetch the token information and update tokenDecimals state
      fetchTokenInfo(selectedToken)
        .then((decimals) => {
          setTokenDecimals(decimals);
        })
        .catch((error) => {
          console.error('Error fetching token info:', error);
        });
    }


  }, [selectedToken]);



  const handleTokenSelect = (event) => {
    setSelectedToken(event.target.value);
  };

  const calculateAdjustedPrice = (price) => {
    if (tokenDecimals !== null) {
      return price / (10 ** tokenDecimals);
    }
    return price;
  };

  const calculateAdjustedPrice2 = (price) => {
    if (tokenDecimals !== null) {
      return price * 10 ** tokenDecimals;
    }
    return price;
  };

  const calculateAdjustedPrice3 = (price,dec) => {
    if (dec !== null) {
      return price / 10 ** dec;
    }
    return price;
  };

  const handleNumNFTsChange = (event) => {
    // setNumNFTs(event.target.value);
  };

  const getMaxMintsForToken = () => {
    const selectedCostInfo = costsInfo.find((cost) => cost.cost_token === selectedToken);
    if (selectedCostInfo) {
      return Math.min(selectedCostInfo.max_mints_per_token - selectedCostInfo.minted_per_token);
    }else{
    return 0;}
  };

  const canMintInPhase = isLoggedIn && phaseInfo.is_phase_live && (phaseInfo.whitelisted_addresses.includes(address) || phaseInfo.whitelisted_addresses.length === 0);
  const isUserMaxInPhaseValid = isLoggedIn && userMax >= phaseInfo.user_max_mints_in_phase;
  const totalMintedInPhase = isUserMaxInPhaseValid ? (phaseInfo.user_max_mints_in_phase)-(phaseInfo.user_minted_in_phase) : 0;
  const remainingMintsInPhase = canMintInPhase ? totalMintedInPhase : 0;
  const remainingMintsOverall = isUserMaxInPhaseValid ? (userMax - phaseInfo.user_total_minted) : 0;


  const handleMintButtonClick = async () => {
    try {
      const tokenAmount = costsInfo.find(cost => cost.cost_token === selectedToken)?.cost_price || new Decimal(0).times(numNFTs);
      const numericTokenAmount = typeof tokenAmount === 'string' ? new Decimal(tokenAmount) : tokenAmount;

      console.log(numericTokenAmount.times(numNFTs).toString()); // Convert to string to display the result
      const totalCost = calculateAdjustedPrice(numericTokenAmount.times(numNFTs));
      const userBalance = adjustedUserBalance;

      if (userBalance >= totalCost) {
        await mintNfts(address, selectedToken, numericTokenAmount.times(numNFTs).toNumber(), numNFTs, numNFTs * 10_000_000 + 50_000_000);
      } else {
        console.log('Insufficient balance to mint NFTs');
      }
    } catch (error) {
      console.error('Error minting NFTs:', error);
    }
  };


  const calculatedPrice = parseFloat(
    (
      calculateAdjustedPrice(costsInfo.find(cost => cost.cost_token === selectedToken)?.cost_price || 0) * numNFTs
    ).toFixed(5)
  );

  const [mintedNFTs, setMintedNFTs] = useState([]);
  const [fetchingError, setFetchingError] = useState(true);
  const [walletCopyMessage, setWalletCopyMessage] = useState('');

useEffect(() => {

  const fetchMintedNFTs = async (address, collection) => {
    try {
      const response = await fetch(network.apiAddress+`/accounts/${address}/nfts?collection=${collectionIdentifier}`);
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Unable to fetch minted NFTs');
    }
  };


  if (address && collectionIdentifier) {
    fetchMintedNFTs(address, collectionIdentifier)
      .then((nfts) => {
        setMintedNFTs(nfts);
        setFetchingError(false);
      })
      .catch((error) => {
        console.error('Error fetching minted NFTs:', error);
        setFetchingError(true);
      });
  }
}, [address, collectionIdentifier]);




  const [tokenBalance, setTokenBalance] = useState(null);
const [adjustedUserBalance, setAdjustedUserBalance] = useState(null);

useEffect(() => {

  const fetchTokenBalanceAndDecimals = async (address, token) => {
    try {
      let tokenBalance, tokenDecimals, adjustedBalance;

      if (token === 'EGLD') {
        const response = await fetch(network.apiAddress+`/accounts/${address}`);
        const data = await response.json();
        tokenBalance = data.balance;
        tokenDecimals = 18; // Assuming EGLD has 18 decimals
        adjustedBalance = calculateAdjustedPrice3(tokenBalance,tokenDecimals);
        //console.log(adjustedBalance);
      } else {
        const response = await fetch(network.apiAddress+`/accounts/${address}/tokens/${token}`);
        const data = await response.json();
        if (data?.statusCode === 404) {
          tokenBalance = 0;
          tokenDecimals = 18;
          adjustedBalance = calculateAdjustedPrice3(tokenBalance,tokenDecimals);
        } else {
          tokenBalance = data.balance;
          tokenDecimals = data.decimals;
          adjustedBalance = calculateAdjustedPrice3(tokenBalance,tokenDecimals);
          //console.log(adjustedBalance);
        }
      }

      return {
        balance: tokenBalance,
        decimals: tokenDecimals,
        adjustedBalance: adjustedBalance
      };
    } catch (error) {
      throw new Error('Unable to fetch token balance and decimals');
    }
  };

  if (selectedToken) {
    fetchTokenBalanceAndDecimals(address, selectedToken)
      .then(({ balance, adjustedBalance }) => {
        setTokenBalance(balance);
        setAdjustedUserBalance(adjustedBalance);
      })
      .catch((error) => {
        console.error('Error fetching token balance and decimals:', error);
      });
  }
}, [selectedToken]);





const calculateTotalCost = (costsInfo, selectedToken, numNFTs) => {
  const costPrice = costsInfo.find(cost => cost.cost_token === selectedToken)?.cost_price || 0;
  //console.log(calculateAdjustedPrice(costPrice * numNFTs));
  return calculateAdjustedPrice(costPrice * numNFTs);
};


const [currentSlide, setCurrentSlide] = useState(0);
const [currentNFT, setCurrentNFT] = useState(null);

const handlePreviousSlide = () => {
  setCurrentSlide((prevSlide) => (prevSlide === 0 ? phaseInfo.latest_mint_nonces.length - 1 : prevSlide - 1));
};


const handleNextSlide = () => {
  setCurrentSlide((prevSlide) => (prevSlide === phaseInfo.latest_mint_nonces.length - 1 ? 0 : prevSlide + 1));
};

const handlePreviousSlide2 = () => {
  setCurrentSlide((prevSlide) => (prevSlide === 0 ? mintedNFTs.length - 1 : prevSlide - 1));
};


const handleNextSlide2 = () => {
  setCurrentSlide((prevSlide) => (prevSlide === mintedNFTs.length - 1 ? 0 : prevSlide + 1));
};

const svgContent = `
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><rect fill='#121212' width='1600' height='900'/><defs><linearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(20,0.5,0.5)'><stop  offset='0' stop-color='#787878'/><stop  offset='1' stop-color='#CF6'/></linearGradient><linearGradient id='b' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(121,0.5,0.5)'><stop  offset='0' stop-color='#F00'/><stop  offset='1' stop-color='#FC0'/></linearGradient></defs><g fill='#FFF' fill-opacity='0' stroke-miterlimit='10'><g  stroke='url(#a)' stroke-width='12.54'><path  transform='translate(-10.5 2.4) rotate(1.5 1409 581) scale(1.006)' d='M1409 581 1450.35 511 1490 581z'/><circle  stroke-width='4.18' transform='translate(-6 6) rotate(1.8 800 450) scale(1.003)' cx='500' cy='100' r='40'/><path  transform='translate(5.4 -18) rotate(18 401 736) scale(1.003)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/></g><g stroke='url(#b)' stroke-width='3.8'><path  transform='translate(36 -2.4) rotate(0.6 150 345) scale(0.994)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/><rect  stroke-width='8.36' transform='translate(-24 -15) rotate(21.6 1089 759)' x='1039' y='709' width='100' height='100'/><path  transform='translate(-36 12) rotate(3.6 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/></g></g></svg>
`;

const copyWallet = () => {
  navigator.clipboard.writeText('erd1t5pf5xfksc4yhkfg70y2xc5vqsfsfm8rmkhlq9pnfgvcyvun522s8taq9f');
  setWalletCopyMessage(' Wallet copied!');
};


useEffect(() => {
  if (phaseInfo.latest_mint_timestamp === null || phaseInfo.latest_mint_timestamp === 0) {
    setDivVisible(false);
  } else if (phaseInfo.latest_mint_timestamp) {
    const timestampInSeconds = phaseInfo.latest_mint_timestamp; // Unix timestamp in seconds
    const nowInSeconds = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
    const numericValue: number = timestampInSeconds as number;

    const timeDifference = nowInSeconds - numericValue; // Difference in seconds
    //console.log(timeDifference);
    setDivVisible(timeDifference <= 120); // 2 minutes in seconds
  }
}, [phaseInfo.latest_mint_timestamp]);




const successContent = (
  <>
  <p style={{ fontSize:'1.7em',fontWeight:'700',textAlign: 'center' }}>🎉 Congratulations on your new HYPEDAF(S)🎉</p>
  {phaseInfo.latest_mint_nonces !== null && phaseInfo.latest_mint_nonces !== undefined && phaseInfo.latest_mint_nonces.length > 0 && !isLoadingNftsIdsFromNoncesList && nftsIdsFromNoncesList ? (
  <div style={{ position: 'relative' }}>
    <img
      src={`https://media.elrond.com/nfts/asset/QmZBVpDqU2yeKqnJYUTY3TH4r4Bf9AS9fHYaYQ2grFEdrq/${nftsIdsFromNoncesList[currentSlide]}.png`}
      style={{ borderRadius: '25px', maxWidth: '25%', height: 'auto', margin: '10px', textAlign: 'center' }}
      alt="HYPEDAF NFT"
    />
    <div className='d-flex justify-content-between mt-3 w-100' style={{ position: 'absolute', top: '50%', left: '0', right: '0', transform: 'translateY(-50%)' }}>
      {phaseInfo.latest_mint_nonces.length > 1 && (
        <>
          <button className='btn btn-link' style={{ fontSize: '2em', color: '#C8FEE0', outline: 'none' }} onClick={handlePreviousSlide} onFocus={(e) => e.target.blur()}>&larr;</button>
          <button className='btn btn-link' style={{ fontSize: '2em', color: '#C8FEE0', outline: 'none' }} onClick={handleNextSlide} onFocus={(e) => e.target.blur()}>&rarr;</button>
        </>
      )}
    </div>
  </div>
) : (
  <p>No data available. Please return to the previous page to see your minted NFTs.</p>
)}


    <p></p>
    <button className="btn btn-success"
      onClick={() => {
        setDivVisible(false); // Hide the div when the button is clicked
      }}
      style={{ fontSize: '1.5em', fontWeight: '500', cursor: 'pointer' }}
    >
      Send me back To Minter
    </button>
  </>
);



const loadingContent = (
  <>
    <img src={'hypeyload.gif'} />
    <p style={{ fontSize: '2em', fontWeight: '700', textAlign: 'center' }}>
      You don't choose HYPEDAF; HYPEDAF chooses you.
    </p>
    <p style={{ marginBottom: '30px', fontSize: '1.3em', fontWeight: '400', textAlign: 'center' }}>
      Your HYPEDAF’s should be served in a few seconds..tic toc
    </p>
  </>
);


  return (
    <div className='container' style={{backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(svgContent)}")`,
    backgroundSize: 'cover', marginTop: '30px'}}>
      <div className='text-white' style={{fontWeight:'400',fontSize:'1.4em'}}>
        <p>If you want to mint 1 HYPEDAF NFT you will need a pair of <a href='https://xoxno.com/collection/HYPEY-794a10?activityType=sales' target='_blank'>1 HYPEY</a> + <a href='https://xoxno.com/collection/HPORTAL-51eca7?activityType=sales' target='_blank'>1 Portal</a> (more pairs, more mints)</p>
        <p>How to mint steps:</p>
        <p>1. Transfer your HYPEY NFTs and Portals to wallet:</p>
        <p style={{ backgroundColor: '#121212', wordBreak: 'break-all' }}>
          <a
            href="https://explorer.multiversx.com/accounts/erd1t5pf5xfksc4yhkfg70y2xc5vqsfsfm8rmkhlq9pnfgvcyvun522s8taq9f"
            target="_blank"
          >
            erd1t5pf5xfksc4yhkfg70y2xc5vqsfsfm8rmkhlq9pnfgvcyvun522s8taq9f
          </a>
        </p>
        <p>
          <button onClick={copyWallet}>
            Click to copy wallet
          </button>
          <b>{walletCopyMessage ? walletCopyMessage : ''}</b>
        </p>
        <p>2. Wait 5-10 mins</p>
        <p>3. For each pair you sent you will get back 1 HYPED token (<a href='https://explorer.multiversx.com/tokens/HYPED-eabbc2' target='_blank'>HYPED-eabbc2</a>) that can be used to mint below</p>
        <p>4. Show us your HYPEDAF mint!</p>
      </div>
      <div className='row'>
        <div className='col-md-6 mx-auto text-center mb-4 mb-md-0' style={{display:'flex',flexDirection:'column', justifyContent: 'center', alignItems: 'center', color: 'white' ,height:'100vh',
        borderTopLeftRadius: '20px',borderLeft:'1px solid rgb(131,58,180)',borderTop:'1px solid rgb(131,58,180)'}}>
        {(mintedNFTs.length <= 0) ? (
            <img
              style={{ borderRadius: '25px', maxWidth: '90%', height: 'auto' }}
              src={imageUrls[currentImageIndex]}
              alt="Logo"
            />
          ) : (
  <div className='d-flex flex-column align-items-center' style={{ marginTop: '20px' }}>
    <p style={{ fontSize: '1.8em', fontWeight: 800, marginTop: '20px' }}>Your new HYPEDAF(s)</p>
    <p style={{ fontSize: '0.9em', fontWeight: 800}}>You own {phaseInfo.user_total_minted} HYPEDAF</p>

    <img
      src={mintedNFTs[currentSlide]?.media[0]?.originalUrl}
      style={{ borderRadius: '25px', maxWidth: '90%', height: 'auto' }}
      alt="HYPEDAF NFT"
    />
    <p style={{ fontSize: '1.8em', fontWeight: 800, marginTop: '20px' }}>{mintedNFTs[currentSlide]?.name}</p>
    <p style={{ fontSize: '1.5em', fontWeight: 800}}>Rank {mintedNFTs[currentSlide]?.rank}</p>
    <p style={{ fontSize: '1.1em', fontWeight: 800}}>Rarity Score : {mintedNFTs[currentSlide]?.score}</p>
    <div className='d-flex justify-content-between mt-3 w-100' style={{ position: 'absolute', top: '40%', left: '0', right: '0', transform: 'translateY(-50%)' }}>
      <button className='btn btn-link' style={{ fontSize: '2em', color: '#C8FEE0',outline: 'none' }} onClick={handlePreviousSlide2} onFocus={(e) => e.target.blur()}>&larr;</button>
      <button className='btn btn-link' style={{ fontSize: '2em', color: '#C8FEE0',outline: 'none' }} onClick={handleNextSlide2} onFocus={(e) => e.target.blur()}>&rarr;</button>
    </div>
  </div>
)}

          <p style={{display:'inline-block',borderRadius:'15px',border:'1px solid white',padding:'3px',fontWeight:'400',fontSize:'1.4em',marginTop:'10px',color:'white'}}>
{globalInfo.total_minted} / {globalInfo.left_to_mint + globalInfo.total_minted} HYPEDAF MINTED
          </p>
          </div>
          <div className='col-md-6 mx-auto  mb-4 mb-md-0' style={{  display:'flex',justifyContent: 'center', alignItems: 'center',color:'white',height:'100vh',
          borderBottomRightRadius: '20px',borderRight:'1px solid rgb(131,58,180)',borderBottom:'1px solid rgb(131,58,180)'}}>
          {isLoadingCostsInfo ? (
            <p>Loading cost information...</p>
          ) : (
            <div>

              <h4>{collectionIdentifier}</h4>
              <p style={{display:'flex',fontWeight:'800',fontSize:'2.1em'}}>Public Mint </p>
              {/* Create radio button selections <h6 style={{fontWeight:'500 !important'}}>You can mint {userMax - totalMinted} Hypeys</h6>*/}

              <div>
    <p style={{display:'inline-block',color:'white',borderRadius:'15px',border:'1px solid white',padding:'5px',fontWeight:'600'}}>
       {phaseInfo.phase_label}{' '}
      {phaseInfo.is_phase_live ? (
        <span style={{ color: 'green' }}>🟢</span>
      ) : (
        <span style={{ color: 'red' }}>🔴</span>
      )}
    </p><br></br>
    <p style={{display:'inline-block',color:'white',borderRadius:'15px',border:'1px solid white',padding:'5px',fontWeight:'600'}}>
    You Have Minted in this Phase: {phaseInfo.user_minted_in_phase} / {phaseInfo.user_max_mints_in_phase}
  </p>
  {phaseInfo && phaseInfo.whitelisted_addresses && phaseInfo.whitelisted_addresses.length > 0 && (
  <p>
    {phaseInfo.whitelisted_addresses.includes(address)
      ? 'You are whitelisted for this phase.'
      : 'You are not whitelisted for this phase. Minting is disabled.'}
  </p>
)}

  </div>

  <p style={{ marginTop: '20px', fontWeight: '700' ,fontSize:'1.9em'}}>Mint Token: </p>
  <div style={{ display: 'flex', flexDirection: 'column'}}>
  {/* Create radio button selections */}
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',

    }}
  >

    {costsInfo.map((cost, index) => (
      (cost.max_mints_per_token - cost.minted_per_token) > 0 && (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Center content horizontally
          }}
        >
         <label htmlFor={`costToken_${index}`} style={{ cursor: 'pointer' }}>
  {cost.cost_token === 'EGLD' || cost.cost_token === 'xEGLD' ? (
    <img
      src='https://s2.coinmarketcap.com/static/img/coins/200x200/6892.png'
      alt={cost.cost_token}
      style={{ width: '45px', height: '45px' }}
    />
  ) : (cost.cost_token === 'HYPED-eabbc2') ? (
    <span>
      <img
        src='/goldhypey.png'
        alt={cost.cost_token}
        style={{ width: '45px', height: '45px' }}
      /> HYPED-eabbc2
      </span>
  ) : (
    <img
      src={network.apiAddress+`/tokens/${cost.cost_token}/logo/png`}
      alt={cost.cost_token}
      style={{ width: '45px', height: '45px' }}
    />
  )}
</label>

          <input
            type="radio"
            name="costToken"
            value={cost.cost_token}
            id={`costToken_${index}`}
            checked={selectedToken === cost.cost_token}
            onChange={() => setSelectedToken(cost.cost_token)}
            style={{ width: '20px', height: '20px', marginTop: '5px' }}
          />
        </div>
      )
    ))}
  </div>
</div>


              {selectedToken && (
                <div style={{marginTop:'30px'}}>
                  {costsInfo.map((cost, index) => {
                    if (cost.cost_token === selectedToken) {
                      return (
                        <p key={index}>
                        Available mints with selected token: {cost?.max_mints_per_token - cost?.minted_per_token}
                      </p>
                      );
                    }
                    return null;
                  })}
                </div>
              )}




                {/* Input for selecting number of NFTs */}
                <div style={{alignItems: 'center', marginTop: '20px',display: 'flex' }}>
                  <p style={{marginBottom:'-5px',fontWeight:'700',fontSize:'2.1em'}}>Quantity: </p>
                  <Form.Group style={{ alignItems: 'center', marginTop: '10px' , marginLeft:'20px' ,display: 'flex' }}>
                    <Button variant='outline-secondary' onClick={() => {
                      if (numNFTs > 1) {
                        setNumNFTs(numNFTs - 1);
                      } else {
                        setNumNFTs(1);
                      }
                    }}>-</Button>
                    <input
                      type='number'
                      value={numNFTs}
                      min={1}
                      max={5}
                      onChange={handleNumNFTsChange}
                      className='form-control text-center mx-2' // Apply Bootstrap styling classes
                      style={{ width: '70px'}} // Adjust the width as needed
                    />
                    <Button style={{marginLeft:'0px'}} variant='outline-secondary' onClick={() => {
                      if (numNFTs < 5) {
                        setNumNFTs(numNFTs + 1);
                      } else {
                        setNumNFTs(5);
                      }
                    }}>+</Button>
                  </Form.Group>
                </div>

                <div style={{marginTop:'20px'}}>
                  <b>Note:</b> Maximum 5 NFTs per transaction
                </div>


           {/* Mint button */}

           {/* mintNfts(userAddress, token, bigAmount, nr of NFTs to mint) */}
           <Button
            style={{ marginTop: '25px', width: '100%', borderRadius: '10px', fontSize: '1.8em', fontWeight: '500', backgroundColor: '#22F6DC', borderColor: '#22F6DC', color: '#121212' }}
            variant="primary"
            disabled={
              globalInfo.left_to_mint == 0 ||
              numNFTs > getMaxMintsForToken() ||
              numNFTs > remainingMintsInPhase ||
              numNFTs <= 0 ||
              !canMintInPhase ||
              !isUserMaxInPhaseValid ||
              (adjustedUserBalance === null || adjustedUserBalance === undefined || calculateTotalCost(costsInfo, selectedToken, numNFTs) > adjustedUserBalance)
            }
            onClick={handleMintButtonClick}
          >
            Mint - {numNFTs} NFT ({calculatedPrice} {selectedToken.split('-')[0]})
          </Button>

          {globalInfo.left_to_mint == 0 &&
            <div style={{marginTop:'20px', textAlign:'center'}}>
            <b>Mint is over. Thank you!</b>
          </div>}


        {adjustedUserBalance !== null && adjustedUserBalance !== undefined && (
          calculateTotalCost(costsInfo, selectedToken, numNFTs) > adjustedUserBalance ? (

            <p style={{ textAlign: 'center', marginTop: '5px' }}>
              Insufficient balance to mint {numNFTs} NFTs.
            </p>
          ) : null
        )}


          {/* Error messages */}
{numNFTs <= 0 ? (
  <p style={{textAlign:'center',marginTop:'5px'}}>Quantity must be greater than 0.</p>
) : numNFTs > getMaxMintsForToken() ? (
  <p style={{textAlign:'center',marginTop:'5px'}}>Cannot mint more than the maximum allowed mints for this token.</p>
) : numNFTs > remainingMintsInPhase ? (
  <p style={{textAlign:'center',marginTop:'5px'}}>Your mint limit in this phase is not sufficient.<br></br> Remaining mints in this phase: {remainingMintsInPhase}</p>
) : numNFTs > userMax-phaseInfo.user_total_minted ? (
  <p>Your mint limit overall is not sufficient. Remaining mints overall: {userMax-phaseInfo.user_total_minted}</p>
) : !selectedToken ? (
  <p style={{textAlign:'center',marginTop:'5px'}}>Please select a token to mint.</p>
) : !costsInfo.find(cost => cost.cost_token === selectedToken) ? (
  <p style={{textAlign:'center',marginTop:'5px'}}>Selected token is not available.</p>
) : !canMintInPhase ? (
  <p style={{textAlign:'center',marginTop:'5px'}}>Your address is not whitelisted for this phase.</p>
) : !isUserMaxInPhaseValid ? (
  <p style={{textAlign:'center',marginTop:'5px'}}>Your mint limit in this phase is not sufficient. Remaining mints in this phase: {remainingMintsInPhase}</p>
) : remainingMintsOverall < remainingMintsInPhase ? (
  <p style={{textAlign:'center',marginTop:'5px'}}>Your mint limit overall is not sufficient. Remaining mints overall: {remainingMintsOverall}</p>
) : null}


<div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#121212',
      zIndex: 9999, // Adjust as needed
      textAlign: 'center',
      visibility: isLoading || hasSucceeded ? 'visible' : 'hidden', // Show/hide based on isLoading or success
    }}
  >
    <div>{hasSucceeded ? null : loadingContent}</div>
  </div>

  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#121212',
      zIndex: 9999, // Adjust as needed
      textAlign: 'center',
      visibility: isLoading || hasSucceeded2 ? 'hidden' : 'visible', // Show/hide based on isLoading or success
    }}
  >
    <div>{hasSucceeded2 ? successContent : null }</div>
  </div>

  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#121212',
      zIndex: 9999, // Adjust as needed
      textAlign: 'center',
      visibility: divVisible && !isLoading ? 'visible' : 'hidden'
      , // Toggle visibility
    }}
  >
    <div>{successContent}</div>
  </div>


            </div>
          )}
        </div>
      </div>
    </div>
  );
};
