import * as React from 'react';
import { Link } from 'react-router-dom';
import { AuthRedirectWrapper } from 'components';
import { routeNames } from 'routes';

const HomePage = () => {
  return (
    <>
    <div className='row w-100 h-100 no-gutters'>
      <div className="col-sm" style={{ height: '100vh' }}>
        <div className="h-50 d-flex flex-column justify-content-center" style={{ fontSize: '45px', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundImage:'url(Multiversx.png)',backgroundSize:'35%',backgroundPosition:'right top',backgroundRepeat:'no-repeat'  }}>
          <span style={{width:'70%',fontSize:'35px',padding:'25px'}}>Let's get<strong> HYPED AF</strong> with this exclusive NFT collection</span>
          <span style={{fontSize:'45px',marginLeft: '30px',marginBottom:'20px'}}><strong>🔥 HYPEDAF 🔥</strong></span>
        </div>
        <div className="h-50 d-flex flex-column justify-content-center" style={{ fontSize: '35px', height:'50%', backgroundImage:'url(hypey.png)',
          backgroundSize:'55%',backgroundPosition:'left center',backgroundRepeat:'no-repeat', backgroundColor: 'black',boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white' }}>
            <span style={{marginRight:'10px',textAlign:'right'}}>What are you</span>
            <span style={{marginRight:'10px',textAlign:'right'}}>waiting for?</span>
            <div style={{textAlign:'end'}}>
              <Link style={{ marginBottom: '60px', marginRight:'25px' }}
                    to={routeNames.unlock}
                    className="btn btn-dark btn-lg mt-3 text-white"
                    data-testid="loginBtn">
                Mint NFT
              </Link>
            </div>
        </div>
      </div>
      <div className="col-sm align-items-center justify-content-between" style={{ height: '100vh' }}>
        <div className="d-flex flex-column justify-content-center align-items-center " style={{ fontSize: '25px',height:'50%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundImage:'url(goldhypey.png)',backgroundSize:'45%',backgroundPosition:'top center',backgroundRepeat:'no-repeat'}}>
          <span className='mt-auto' style={{fontSize:'25px',marginTop:'15px'}}><strong>Join HYPEDAF</strong></span>
          <span style={{width:'60%',fontSize:'18px',marginTop:'15px',marginBottom:'15px',textAlign:'center'}}>A collection of 2222 NFTs that will transform your HYPEYs once they enter the Portal</span>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ fontSize: '45px',height:'50%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white' ,
      }}>
        <span style={{fontSize:'35px',padding:'20px',textAlign:'center'}}><strong> Transform your HYPEY using the Portal</strong> </span>
        <span style={{fontSize:'25px'}}>Are you as excited as we are? </span>
        </div>
      </div>
      <div className="col-sm" style={{ height: '100vh' }}>
        <div className="d-flex flex-column justify-content-center" style={{ fontSize: '45px',height:'50%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundImage:'url(hypeysleep.png)',backgroundSize:'55%',backgroundPosition:'right top',backgroundRepeat:'no-repeat' }}>
          <span className='mt-auto' style={{width:'65%',fontSize:'40px',padding:'55px'}}>Hand drawn generative collection with <strong>22 unique 1/1s</strong></span>
        </div>
        <div className="d-flex flex-column justify-content-center" style={{ fontSize: '35px',height:'50%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundImage:'url(lauhypey.png)',backgroundSize:'55%',backgroundPosition:'left center',backgroundRepeat:'no-repeat' }}>
          <span style={{textAlign:'right',marginRight:'20px'}}>Mint with</span>
          <span style={{textAlign:'right',marginRight:'20px'}}>HYPED</span>
          <div style={{textAlign:'end'}}>
          <img style={{marginRight:'20px'}} src="goldhypey.png" width={'60px'} height={'auto'}></img>
          </div>

        </div>
      </div>
    </div>
  </>

  );
};

export const Home = () => (
  <AuthRedirectWrapper>
    <HomePage />
  </AuthRedirectWrapper>
);
